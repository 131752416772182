<template>
    <div id="creativite" class="page-wrapper page-creativite">
        <!-- SubHero -->
        <header class="sub-hero site-max-width" hero-transition-group data-gsap-change-color="default">
            <div class="container">
                <h1 class="small-title title" data-inview="fadeInUp" data-delay="100">Créativité</h1>
                <p class="big-text text" data-inview="fadeInUp" data-delay="200">
                    Toutes nos têtes créatives <br />
                    sont 360. Mais on a un bon chiro.
                </p>
                <img src="@/assets/imgs/cretivite-hero.jpg" alt="Créativité" class="main" data-inview="fadeInUp" data-delay="500" />
            </div>
        </header>

        <!-- Content -->
        <div id="zone-methodologie" class="content-text site-max-width" data-gsap-change-color="colorAccent2" data-bg-2 hero-transition-group>
            <div class="container">
                <div class="spacer"></div>
                <article>
                    <div class="cols">
                        <div class="left">
                            <h3 class="small-title">La méthodologie</h3>
                        </div>
                        <div class="right">
                            <p class="small-text right-text">
                                Chaque marque vient immanquablement avec une histoire, et notre travail est de la raconter, en imaginant des concepts sur des plateformes créatives adaptées à chacun de nos clients. Et parce qu’on a de la suite dans les idées, on fait en sorte que les messages et les images signés par cette marque soient cohérents et pertinents, peu importe le canal ou le média. Tout en s’assurant que le produit créatif soit de haut niveau.
                                <br /><br />
                                Chez nous, les créatifs sont aussi stratèges, les conseillers ont le cerveau en mode client et les producteurs ont le placement média qui leur coule dans les veines. Chacune de nos équipes réfléchit à un objectif commun, en s’assurant d’avoir toujours une longueur d’avance sur la prochaine étape. Avec l'amalgame de ressources qui nous entourent, penser 360 va de soi. Travailler en silo, chez nous, c’est non (sauf si on parle de Silo 57, eux on les aime). Quand on met nos talents en commun, qu’on se pose les bonnes questions, qu’on se challenge et qu’on inclut tout le monde, c’est là qu’on offre le meilleur de nous-mêmes.
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>

        <!-- Content -->
        <div id="zone-creativite" class="content-text splits no-border site-max-width" data-gsap-change-color="colorAccent2" data-bg-2 hero-transition-group>
            <div class="container">
                <div class="top">
                    <h2 class="big-title title" data-inview="fadeInUp" data-delay="100">
                        On a les crayons les plus aiguisés de la boîte, et on n’a pas peur de dépasser.
                    </h2>
                </div>
                <article class="split">
                    <div class="content">
                        <h3 class="regular-title" data-inview="fadeInUp" data-delay="200">Publicité</h3>
                        <p class="small-text" data-inview="fadeInUp" data-delay="300">
                            Peu importe le geste qu’on pose en publicité, il doit cocher 3 cases : être surprenant, être pertinent et être attribuable. Il n’y a rien de pire pour une marque que de passer inaperçue. Notre travail doit donc être remarqué et remarquable. Dans une volonté de notoriété, de considération ou de conversion, le résultat découle de l’empreinte laissée sur la cible. Et notre force est d’articuler des messages qui captent l’attention et ne laissent personne indifférent.
                        </p>
                    </div>
                    <div class="visual" data-inview="revealRight" data-delay="100">
                        <img src="@/assets/imgs/publicite.jpg" alt="Publicité" />
                    </div>
                    <img src="@/assets/imgs/icon-bulb.png" alt="Bulb" class="float-icon" data-gsap-parrallax-scroll>
                </article>
                <article class="split reverse">
                    <div class="content">
                        <h3 class="regular-title" data-inview="fadeInUp" data-delay="100">Création de contenu</h3>
                        <p class="small-text" data-inview="fadeInUp" data-delay="200">
                            On peut rejoindre la cible à peu près partout, mais pour passer à la prochaine étape, on doit engager la conversation. C’est ce qui nous permet de créer de vrais liens, de susciter un intérêt profond et, surtout, de nous démarquer dans la jungle que sont les réseaux sociaux. Chez nous, on sait faire parler les marques, mais on sait aussi quand c’est le temps d’écouter.
                        </p>
                    </div>
                    <div class="visual" data-inview="revealLeft" data-delay="100">
                        <img src="@/assets/imgs/creation-contenu.jpg" alt="Création de contenu" />
                    </div>
                </article>
                <article class="split">
                    <div class="content">
                        <h3 class="regular-title" data-inview="fadeInUp" data-delay="100">Design graphique</h3>
                        <p class="small-text" data-inview="fadeInUp" data-delay="200">
                            C’est souvent dans les détails, les couleurs, les motifs et les images qu’on arrive le mieux à comprendre la personnalité d’une marque. On se donne donc la mission de faire du beau avec à peu près tout. Nos designers arrivent à donner du charisme à l’emballage le plus simple et à transformer un affichage promo en une œuvre parfaitement équilibrée.
                        </p>
                    </div>
                    <div class="visual" data-inview="revealRight" data-delay="100">
                        <img src="@/assets/imgs/design-graphique.jpg" alt="Design graphique" />
                    </div>
                    <img src="@/assets/imgs/icon-tiger.png" alt="Tiger" class="float-icon" data-gsap-parrallax-scroll>
                </article>
                <article class="split reverse" data-gsap-change-color="colorAccent2">
                    <div class="content">
                        <h3 class="regular-title" data-inview="fadeInUp" data-delay="100">Image de marque</h3>
                        <p class="small-text" data-inview="fadeInUp" data-delay="200">
                            Décider de son image de marque, ça ne s’arrête pas à trouver un beau logo. C’est toute l’identité de l’entreprise qui se doit d’être définie, dès le départ. Le nom, les couleurs, le ton. Avant même de commencer à parler, on doit dégager quelque chose de fort, qui inspire confiance et qui se démarque. Et chez nous, on excelle dans l’art de la première impression.
                        </p>
                    </div>
                    <div class="visual" data-inview="revealLeft" data-delay="100">
                        <img src="@/assets/imgs/image-marque.jpg" alt="Image de marque" />
                    </div>
                </article>
                <div class="testimonial" data-gsap-change-color="default">
                    <p class="big-text" data-inview="fadeInUp" data-delay="100">
                        « La ligne entre les objectifs d’affaires et la création hors pair est parfois très fine. Heureusement, on a un bon équilibre. »
                    </p>
                    <p class="small-text">— Hugues Choquette, directeur principal, création.</p>
                </div>
                <div class="lone-img" data-gsap-change-color="default">
                    <img src="@/assets/imgs/creativite-reel.jpg" alt="Idées" />

                    <div class="content">
                        <a href="https://vimeo.com/706656876" class="glightbox" >
                            <h2 class="video-title big-title title" data-inview="fadeInUp" data-delay="100">
                                Nos murs sont peut-être beiges,<br/> mais nos idées sont colorées. <br/> Regardez. <br/>

                                <svg
                                    class="play-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="75.347"
                                    height="75.348"
                                    viewBox="0 0.026 75.347 75.348"
                                    data-inview="fadeInUp" data-delay="200"
                                >
                                    <g>
                                        <path
                                            d="M30.733 56.192V18.54c0-.983.591-1.868 1.499-2.244a2.427 2.427 0 0 1 .788-.18c.047-.003.094-.005.14-.005a2.424 2.424 0 0 1 1.718.712L53.704 35.65a2.416 2.416 0 0 1 .664 1.239 2.425 2.425 0 0 1-.664 2.195L34.878 57.91a2.428 2.428 0 0 1-1.718.712 2.414 2.414 0 0 1-.163-.006 2.423 2.423 0 0 1-.765-.18 2.428 2.428 0 0 1-1.5-2.243Zm4.857-31.789v25.925l12.962-12.962L35.59 24.403Z"
                                            fill="#ceffb4"
                                            fill-rule="evenodd"
                                            data-name="Path 14"
                                        />
                                        <path
                                            d="M37.674-.474c20.85 0 37.673 16.824 37.673 37.674a37.553 37.553 0 0 1-37.673 37.674A37.483 37.483 0 0 1 .004 37.813 39.296 39.296 0 0 1 0 37.2 37.554 37.554 0 0 1 37.674-.474Zm0 70.496c18.063 0 32.72-14.655 32.72-32.822 0-18.063-14.657-32.72-32.72-32.72C19.51 4.48 4.851 19.137 4.851 37.2a32.773 32.773 0 0 0 32.823 32.822Z"
                                            fill="#ceffb4"
                                            fill-rule="evenodd"
                                            data-name="Path 13"
                                        />
                                    </g>
                                </svg>
                            </h2>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer Nav -->
        <router-link :to="{ name: 'HomePage-fr' }" class="full-video footer-nav" data-gsap-change-color="default" data-gsap-activate-footer>
            <div class="container">
                <img class="main" src="@/assets/imgs/creativite-footer-nav.jpg" alt="Grand terrain de jeu" />
                <div class="content">
                    <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                        Pour tout savoir sur notre grand terrain de jeu, c’est ici.

                        <!-- creativite-footer.png -->

                        <!-- Quand on a une idée, on la fait. Découvrez notre machine de production. -->
                        <!-- Toutes nos productions partent de quelque part. Voyez ce qu’on fait en créa. -->
                        <!-- Pour tout savoir sur notre grand terrain de jeu, c’est ici. -->

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            viewBox="139 15409.606 95.627 88.742"
                        >
                            <path
                                d="m190.256 15409.607-4.973 4.973 35.829 35.828H139v7.012h82.239l-35.956 35.956 4.973 4.973 44.37-44.371-44.37-44.371Z"
                                fill="#ceffb4"
                                fill-rule="evenodd"
                                data-name="Path 471"
                            />
                        </svg>
                    </h2>
                    <!-- svg -->
                </div>
            </div>
        </router-link>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(ScrollToPlugin)

import GLightbox from 'glightbox'
import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Creativite',

    components: {
        Footer,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        setTimeout(() => {
                            this.initGsapBgColors()

                            this.gsapActivateFooter()

                            gsap.utils.toArray('[data-gsap-parrallax-scroll]').forEach(element => {
                                gsap.to(element, {
                                    scrollTrigger: {
                                        trigger: element,
                                        start: 'top bottom',
                                        end: 'bottom top',
                                        scrub: 1.475,
                                    },
                                    y: '-25%',
                                    ease: 'none',
                                })
                            })

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {}
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 45%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-creativite')) {
                            return
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-creativite').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-creativite').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-creativite')) {
                            return
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-creativite').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-creativite').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-creativite')) {
                        return
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-creativite')) {
                        return
                    }
                    footerFold.classList.remove('is-active')
                },
            })
        }
    },
}
</script>
